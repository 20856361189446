<template>
  <v-content>
    <router-view />
    <!--    <dashboard-core-footer v-if="coreFooter" />-->
    <v-snackbar
      v-model="snack"
      :timeout="16000"
      :top="true"
      color=""
    >
      {{ snacky.t }}
      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snacky.c"
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',
    components: {
      // DashboardCoreFooter: () => import('./Footer'),
    },
    data () {
      return {
        snack: false,
      }
    },
    computed: {
      snacky () {
        return this.$store.getters.getSnack
      },
      coreFooter () {
        return this.$store.getters.getCoreFooter
      },
    },
    watch: {
      snacky () {
        this.snack = true
      },
    },
    beforeMount () {
      const token = localStorage.getItem('token')
      const userID = this.userData._id

      if (!token || !userID) {
        this.$router.push('/login')
      }
    },
  }
</script>
